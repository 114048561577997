/* eslint-disable react/display-name */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _get from 'lodash/get';
import CampaignList from './CampaignList';
import CampaignDescription from './CampaignDescription';
import PointTotal from './PointTotal';
import ReferralCode from './ReferralCode';
import CampaignBanner from './CampaignBanner';
import { CAMPAIGN_TABLE_COLUMNS } from '../../utilities/constants';
import './style.scss';
import _, { get } from 'lodash';

const Component = ({
  onHandleCopyCode,
  pointHistory,
  onGetPointHistory,
  onHandleChangePoint,
  campaign
}) => {
  const campaignId = _.get(campaign, 'objectId');
  const webImages = _get(campaign, 'webImages');
  const images = _get(campaign, 'images');
  const faqLink = _get(campaign, 'faqLink');
  
  useEffect(() => {
    onGetPointHistory({
      page: _get(pointHistory, 'page', 1),
      limit: _get(pointHistory, 'limit', 1)
    });
  }, []);

  const isHaveLoadMore = _get(pointHistory, 'data.dataPage.current_page', 1) < _get(pointHistory, 'data.dataPage.last_page', 1);
  const handleLoadMore = () => {
    onGetPointHistory({
      page: _get(pointHistory, 'page', 1),
      limit: _get(pointHistory, 'limit', 1)
    });
  };

  return (
    <>
      {/* Banner */}
      <div className='campaign-wrapper'>
        <p className='title'>{_get(campaign, 'title', '最大1万円分のアマギフキャンペーン')}</p>

        <div className='content-box-wrapper'>
          {!campaignId && <div className='empty-wrapper'>
            <div className='empty-content'>
              <img style={{ margin: '0 auto' }} src="/images/empty-campaign.svg" />
              <div className='empty-text'>キャンペーンは終了しました</div>
            </div>
          </div>}
          {
            campaignId && <CampaignBanner 
              list={_get(campaign, 'webImages', []).filter((_item, index) => index === 0)}
              type="thumbLarge"
            />
          }
        
          {/* Description */}
          {campaignId && _get(campaign, 'description') && (
            <CampaignDescription content={_get(campaign, 'description')}/>
          )}

          <div className='container-main'>
            {/* Referral code */}
            {campaignId && <div className='rows'>
              <p className='label-thin-text'>紹介コード</p>
              <ReferralCode onHandleCopyCode={onHandleCopyCode} value={_get(campaign, 'inviteCode')} />
            </div>}

            {/* Campaign Total */}
            <div className='rows'>
              <p className='label-middle-text'>ポイント</p>
              <PointTotal campaignId={campaignId} onHandleChangePoint={onHandleChangePoint} value={_get(pointHistory, 'data.userPoint')} />
            </div>

            {/* Campaign table */}
            <div className='rows' style={{
              marginBottom: 0
            }}>
              <p className='label-text'>獲得履歴</p>
              <CampaignList 
                isHaveLoadMore={isHaveLoadMore}
                columns={CAMPAIGN_TABLE_COLUMNS} 
                list={_get(pointHistory, 'data.dataHistoryList', [])}
                onLoadMore={handleLoadMore}
                keyIndex={'reference_id'}
                formatCol={[
                  {
                    name: CAMPAIGN_TABLE_COLUMNS[0].name,
                    render: (val) => <>{moment(val).format('YYYY/MM/D')}</>
                  },
                  {
                    name: CAMPAIGN_TABLE_COLUMNS[1].name,
                    render: (val) => <span style={{ color: '#53525A', fontWeight: 700 }}>{new Intl.NumberFormat().format(val || 0)}pt</span>
                  },
                  {
                    name: CAMPAIGN_TABLE_COLUMNS[2].name,
                    render: (val) => <>{moment(val).format('YYYY/MM/D')}まで</>
                  }
                ]}
                isLoading={_get(pointHistory, 'loading', false)}
              />
            </div>
            
            {/* Info of Campaign (Banner on bottom) */}
            <div className='banner-wrapper'>
              <img className='desktop-banner' src={get(webImages, '[1].thumbLarge', '/images/default-campaign-web-image.png')} />
              <img className='mobile-banner' src={get(images, '[1].thumbLarge', '/images/default-mobile-campaign-image.png')} />
            </div>
          </div>
        </div>
        {faqLink && <a href={faqLink} target="_blank" rel="noopener noreferrer">
          <div className='faq-wrapper'>
            <div style={{ display: 'flex', gap: 16 }}>
              <img src="/images/faq.svg" />
              <p>ポイントキャンペーンに関するQ＆A</p>
            </div>
            <img src="/images/gray-right-arrow.svg" />
          </div>
        </a>}
      </div>
    </>
  );
};

Component.propTypes = {
  onHandleCopyCode: PropTypes.func,
  data: PropTypes.object,
  onGetPointHistory: PropTypes.func,
  onHandleChangePoint: PropTypes.func,
  campaign: PropTypes.object,
  pointHistory: PropTypes.object
};

export default Component;