/* eslint-disable no-prototype-builtins */
import isEmail from 'sane-email-validation';
import _ from 'lodash';
import { isEmpty, universityHasExtraInput } from 'utilities/handleValues';
import { isValidDate } from 'utilities/date';
import moment from 'moment-timezone';
import { VALIDATE_MESSAGE } from 'utilities/messages';
import Immutable from 'immutable';
import { KATAKANA_REGEX } from 'utilities/constants';
// import { PHONE_REGEX } from 'utilities/constants';

// function telephoneCheck(str) {
//   return !!PHONE_REGEX.test(str);
// }

const validate = (values) => {
  const errors = {};
  // photos
  const photos = values.get('photos');
  const hasNonEmptyObject =
    photos &&
    photos.size > 0 &&
    photos?.some((photo) => {
      const isExistObjectId = photo.get('objectId');
      return Immutable.Map(photo).size > 0 && isExistObjectId;
    });
  if (!hasNonEmptyObject) {
    errors.photos = VALIDATE_MESSAGE.REQUIRED;
  }

  const firstName = values.get('firstName');
  // Validate firstName
  if (isEmpty(firstName)) {
    errors.firstName = VALIDATE_MESSAGE.REQUIRED;
  } else if (firstName.length > 10) {
    errors.firstName = VALIDATE_MESSAGE.MAX_CHARS(10);
  }

  const lastName = values.get('lastName');
  // Validate lastName
  if (isEmpty(lastName)) {
    errors.lastName = VALIDATE_MESSAGE.REQUIRED;
  } else if (lastName.length > 10) {
    errors.lastName = VALIDATE_MESSAGE.MAX_CHARS(10);
  }

  const firstNameHiragana = values.get('firstNameHiragana');
  // Validate firstNameHiragana
  if (isEmpty(firstNameHiragana)) {
    errors.firstNameHiragana = VALIDATE_MESSAGE.REQUIRED;
  } else if (firstNameHiragana.length > 10) {
    errors.firstNameHiragana = VALIDATE_MESSAGE.MAX_CHARS(10);
  } else if (!KATAKANA_REGEX.test(firstNameHiragana)) {
    errors.firstNameHiragana = VALIDATE_MESSAGE.INVALID_KATAKANA;
  }

  const lastNameHiragana = values.get('lastNameHiragana');
  // Validate lastNameHiragana
  if (isEmpty(lastNameHiragana)) {
    errors.lastNameHiragana = VALIDATE_MESSAGE.REQUIRED;
  } else if (lastNameHiragana.length > 10) {
    errors.lastNameHiragana = VALIDATE_MESSAGE.MAX_CHARS(10);
  } else if (!KATAKANA_REGEX.test(lastNameHiragana)) {
    errors.lastNameHiragana = VALIDATE_MESSAGE.INVALID_KATAKANA;
  }

  // birthday
  const birthday = values.get('birthday');
  if (!birthday) {
    errors.birthday = VALIDATE_MESSAGE.REQUIRED;
  } else if (birthday.length !== 10 || !isValidDate(birthday)) {
    errors.birthday = 'Invalid date';
  }

  if (!errors.birthday && moment().diff(birthday, 'years') < 15) {
    errors.birthday = '誕生日は15歳以上である必要があります';
  }
  // gender
  const gender = values.get('gender');
  if (!gender) {
    errors.gender = 'この値は必須です。';
  }
  //phone
  // const phone = values.get('phone');
  // if (!phone) {
  //   errors.phone = VALIDATE_MESSAGE.REQUIRED;
  // } else if (!telephoneCheck(phone)) {
  //   errors.phone = 'Invalid Phone Number';
  // }
  // email
  const email = values.get('email');
  if (!email) {
    errors.email = VALIDATE_MESSAGE.REQUIRED;
  } else if (!isEmail(email)) {
    errors.email = 'Invalid email';
  }
  //
  if (!errors.hasOwnProperty('studentProfile')) {
    errors.studentProfile = {};
  }
  //
  // club
  const club =
    values.get('studentProfile') && values.get('studentProfile').get('club');
  if (!club || _.isEmpty(_.trim(club))) {
    if (!errors.hasOwnProperty('studentProfile')) {
      errors.studentProfile = {};
    }
    errors.studentProfile.club = VALIDATE_MESSAGE.REQUIRED;
  }
  // intro
  const introduction =
    values.get('studentProfile') &&
    values.get('studentProfile').get('introduction');
  if (!introduction || _.isEmpty(_.trim(introduction))) {
    errors.studentProfile.introduction = VALIDATE_MESSAGE.REQUIRED;
  }

  //companytag
  const companyTags =
    values.get('studentProfile') &&
    values.get('studentProfile').get('companyTags');
  if (!companyTags || _.isEmpty(companyTags)) {
    errors.studentProfile.companyTags = VALIDATE_MESSAGE.REQUIRED;
  }
  // university
  const universityId =
    values.get('studentProfile') &&
    values.get('studentProfile').get('universityId');
  if (!universityId || _.isEmpty(_.trim(universityId))) {
    if (!errors.hasOwnProperty('studentProfile')) {
      errors.studentProfile = {};
    }
    errors.studentProfile.universityId = 'この値は必須です。';
  } else {
    const hasExtraInput = universityHasExtraInput(universityId);
    if (hasExtraInput) {
      const university =
        values.get('studentProfile') &&
        values.get('studentProfile').get('university');
      if (!university) {
        if (!errors.hasOwnProperty('studentProfile')) {
          errors.studentProfile = {};
        }
        errors.studentProfile.university = 'この値は必須です。';
      }
    }
  }
  // major
  const major =
    values.get('studentProfile') && values.get('studentProfile').get('major');
  if (!major || _.isEmpty(_.trim(major))) {
    errors.studentProfile.major = VALIDATE_MESSAGE.REQUIRED;
  } else if (major.length > 40) {
    errors.studentProfile.major = 'Max 40 character length';
  }
  // majorCategory
  const majorCategory =
    values.get('studentProfile') &&
    values.get('studentProfile').get('majorCategory');
  if (!majorCategory) {
    errors.studentProfile.majorCategory = VALIDATE_MESSAGE.REQUIRED;
  }
  // graduationYear
  const graduationYear =
    values.get('studentProfile') &&
    values.get('studentProfile').get('graduationYear');
  if (!graduationYear) {
    errors.studentProfile.graduationYear = VALIDATE_MESSAGE.REQUIRED;
  }
  // district
  const district = values.get('studentProfile') && values.get('studentProfile').get('district');
  if (!district) {
    errors.studentProfile.district = 'この値は必須です。';
  }
  return errors;
};

export default validate;