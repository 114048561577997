/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import Component from '../../components/CampaignPoint/Component';
import { changePointRequest, getPointHistoryRequest } from './action';
import { setCommonPopup } from 'containers/Popup/actions';
import { studentResendEmail } from 'containers/StudentUpdateProfile/actions';
import { get } from 'lodash';
import Loading from 'components/Loading';

const CampaignPoint = ({
  currentCampaign,
  pointHistory,
  onGetPointHistory,
  currentUser,
  showConfirmPopup,
  resendEmail,
  history,
  changePoint,
  isLoading
}) => {

  useEffect(() => {
    if (currentUser && !currentUser.emailVerified) {
      showConfirmPopup({
        title: 'メール認証が必要です',
        message: 'このキャンペーンにアクセスするには、メールを認証する必要があります。',
        onConfirm: () => resendEmail({ showSuccessToast: true }),
        btnConfirm: 'メールを認証する',
        isAlert: true
      });
      history.replace('/');
    }
  }, [currentUser]);

  const onHandleCopyCode = (value) => {
    navigator.clipboard.writeText(value);
  };

  const showChangePointSuccessPopup = () => {
    showConfirmPopup({
      title: 'ギフト券の交換申請受付ました。',
      message: '最後に社長メシLINE友達追加をお願いします。 \nLINEにて今後のご案内差し上げます。',
      confirmButtonProps: {
        style: {
          backgroundColor: '#06C755',
          backgroundImage: 'unset'
        }
      },
      onConfirm: () => window.open(process.env.REACT_APP_LINE_URL, '_blank'),
      btnConfirm: 
        <span style={{ 
          display: 'inline-flex', alignItems: 'center' 
        }}>
          <img style={{ marginRight: 8 }} src='images/white-line.svg' alt='LineIcon' />友だち追加
        </span>,
      isAlert: true,
      closeButton: true,
      closeOnConfirm: false
    });
  };

  const showChangePointErrorPopup = (e) => {
    const errorCode = get(e, 'code');
    const errorMessage = get(e, 'error');
    let popupPayload = {
      title: 'ポイント交換条件不足エラー',
      message: `${errorMessage}\n\nポイント交換のためにイベントに参加する`,
      isAlert: true,
      closeButton: true
    };
    switch (errorCode) {
      case 9007:
        popupPayload = {
          ...popupPayload,
          btnConfirm: 'イベント一覧へ',
          onConfirm: () => history.push('/event-list')
        };
        break;
      case 9006: 
        popupPayload = {
          ...popupPayload,
          btnConfirm: 'キャンペーンページへ'
        };
        break;
      default:
        popupPayload = {
          message: errorMessage,
          isAlert: true,
          btnConfirm: '閉じる'
        };
    }
    showConfirmPopup(popupPayload);
  };

  const handleRequestChangePoint = () => {
    showConfirmPopup({
      title: 'ギフト券の交換申請をします。',
      message: '1回の申請で交換できるポイントは5000ポイントです。手続きまでに1週間いただいております。',
      onConfirm: () => changePoint()
        .then(showChangePointSuccessPopup)
        .catch(showChangePointErrorPopup),
      btnConfirm: '申請する',
      btnCancel: 'キャンセル'
    });
  };

  if (isLoading) {
    return (
      <Loading />
    );
  }
  return (
    <Component 
      pointHistory={pointHistory}
      campaign={currentCampaign}
      onHandleCopyCode={onHandleCopyCode} 
      onHandleChangePoint={handleRequestChangePoint}
      onGetPointHistory={onGetPointHistory} 
    />
  );
};

CampaignPoint.propTypes = {
  currentCampaign: PropTypes.object,
  pointHistory: PropTypes.object,
  onGetPointHistory: PropTypes.func,
  currentUser: PropTypes.func,
  showConfirmPopup: PropTypes.func,
  resendEmail: PropTypes.func,
  history: PropTypes.object,
  changePoint: PropTypes.func,
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    currentCampaign: state.get('campaign').get('data'),
    pointHistory: state.get('campaign').get('pointHistory'),
    currentUser: state.get('account').get('data'),
    isLoading: state.get('campaign').get('loading')
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetPointHistory: (data) => dispatch(getPointHistoryRequest(data)),
  showConfirmPopup: (payload) => dispatch(setCommonPopup('confirm', payload)),
  resendEmail: (payload) => dispatch(studentResendEmail(payload)),
  changePoint: (payload) => dispatch(changePointRequest(payload))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS
)(CampaignPoint);