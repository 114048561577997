import _ from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
// Only handle 2 layer objects
export const trimObjectList = (objectList) =>
  _.mapValues(objectList, (value) => {
    if (typeof value === 'string') return _.trim(value);
    if (Array.isArray(value)) return value;
    if (typeof value === 'object')
      return _.mapValues(value, (item) => {
        if (typeof item === 'string') return _.trim(item);
        return item;
      });
    return value;
  });

export function universityHasExtraInput(universityId) {
  const state = window.store.getState && window.store.getState();
  let availableUniversities = [];
  if (state) {
    availableUniversities = state.get('resources').get('availableuniversity');
    if (!availableUniversities) {
      availableUniversities = [];
    }
  }
  const university = _.find(availableUniversities, { objectId: universityId });
  return university && university.hasExtraInput;
}

export function getAgeFromISOString(dateString) {
  if (!dateString) {
    return undefined;
  }
  const sliceDate = dateString.slice(0, 10);
  const age = moment().diff(moment(sliceDate, 'YYYY-MM-DD'), 'years');
  return age;
}

export function getCurrentNationSelect() {
  return window.sessionStorage.getItem('select_nation_code');
}

export function getVerifyPopupPayload({ email }) {
  return {
    title: '認証メールを送信しました。',
    message: `${email}に認証メールを送信しました。\nメールを認証すると、サービスを利用することができます。`,
    btnConfirm: '確認',
    isAlert: true,
    subContent:
      'もし届かなかった場合は、メールアドレスが\n間違えているか迷惑メールフォルダに\n格納されている可能性があります。',
  };
}

export function formatOfferMessage(message) {
  const datetimeRegex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2} ~ \d{2}:\d{2}/g;
  return message.replace(datetimeRegex, (match) => {
    const [datePart, startTime, endTime] = match.split(/[\s~]+/);
    const startDate = momentTz
      .tz(`${datePart} ${startTime}`, 'YYYY-MM-DD HH:mm', 'Asia/Tokyo')
      .local()
      .format('YYYY-MM-DD HH:mm');
    const endDate = momentTz
      .tz(endTime, 'HH:mm', 'Asia/Tokyo')
      .local()
      .format('HH:mm');
    return `${startDate} ~ ${endDate}`;
  });
}

//https://c2cdoc.atlassian.net/browse/V00200-3251
export function calculateGraduationYearFromBirthYear(dateString, defaultValue) {
  let defaultGraduationYear = defaultValue || '';
  if (moment().diff(dateString, 'years') < 15) return defaultGraduationYear;
  const date = new Date(dateString);
  const birthYear = date.getFullYear();
  const birthMonth = date.getMonth() + 1; // January is 0, so add 1 to get the correct month
  const currentYear = new Date().getFullYear();
  let graduationYear = birthMonth < 4 ? birthYear + 22 : birthYear + 23;
  // if the graduation year < current year,
  // the graduation year won't auto-fill, it will be empty
  if (graduationYear < currentYear) {
    graduationYear = defaultGraduationYear;
  }
  return graduationYear;
}

// Helper function to check if a string is empty
export const isEmpty = (str) => !str || _.isEmpty(_.trim(str));
