import React from 'react';
import PropTypes from 'prop-types';
import '../style.scss';
import './style.scss';

const PointTotal = ({
  value,
  onHandleChangePoint,
  campaignId
}) => {
  return (
    <div className='campaign-total-container'>
      <div style={{
        backgroundImage: 'url(/images/campaign-background.svg)'
      }} className='campaign-background-left'></div>
      <div style={{
        backgroundImage: 'url(/images/campaign-background.svg)'
      }} className='campaign-background-right'></div>

      <div className='campaign-total-content'>
        <p className='text'>現在<span className='number-text'>
          {`${new Intl.NumberFormat().format(value || 0)}pt`}</span>保有してます。
        </p>
        <button onClick={onHandleChangePoint} className='campaign-button' disabled={!campaignId}>ギフト券交換申請する</button>
        <p className="change-point-remind">キャンペーン終了後はギフト券交換申請ができません。</p>
      </div>
    </div>
  );
};

PointTotal.propTypes = {
  value: PropTypes.string,
  onHandleChangePoint: PropTypes.func,
  campaignId: PropTypes.string
};

export default PointTotal;