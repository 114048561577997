import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Tags from 'components/Tags';
import ElementRenderer from './ElementRenderer';
import { Tooltip } from 'react-bootstrap';

let tagsList = [];
var ErrorTimeOut = null;

class TagsField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  addItem = (value) => {
    let selectedTags = this.props.input.value ? this.props.input.value : [];
    selectedTags = selectedTags.toJS ? selectedTags.toJS() : selectedTags;

    if (
      this.props.maxSelectable &&
      selectedTags.length >= this.props.maxSelectable
    ) {
      this.setState({ error: 'タグは6つまで選択できます' });
      clearTimeout(ErrorTimeOut);

      ErrorTimeOut = setTimeout(() => {
        this.setState({ error: null });
      }, 1700);

      return;
    }

    if (!selectedTags.includes(value)) {
      let newVal = selectedTags.slice(0);
      newVal.push(value);
      this.props.input.onChange(newVal);
    }
  };

  removeItem = (value) => {
    let selectedTags = this.props.input.value ? this.props.input.value : [];
    selectedTags = selectedTags.toJS ? selectedTags.toJS() : selectedTags;

    let index = selectedTags.indexOf(value);
    if (index >= 0) {
      let newVal = selectedTags.slice(0);
      newVal.splice(index, 1);
      this.props.input.onChange(newVal);
    }
  };

  showError = () => {
    let error = this.state.error;

    return (
      <Tooltip
        id="error-flash-tooltip"
        className={`${error ? 'show' : ''}`}
        placement="top"
      >
        {error}
      </Tooltip>
    );
  };

  render() {
    let selectedTags = this.props.input.value ? this.props.input.value : [];
    selectedTags = selectedTags.toJS ? selectedTags.toJS() : selectedTags;

    return (
      <Fragment>
        {!this.props.customForm ? (
          <ElementRenderer
            {...this.props}
            render={() => (
              <div className="event-detail-tag">
                <Tags
                  tags={this.props.tag || tagsList}
                  selectedTags={selectedTags}
                  addItem={this.addItem}
                  removeItem={this.removeItem}
                  maxSelectable={this.props.maxSelectable}
                />
                {this.showError()}
              </div>
            )}
          />
        ) : (
          <Fragment>
            <div
              className={`custom-tagsfield-container ${
                this.props.meta.error ? 'error-form' : null
              }`}
            >
              {this.props.label ? (
                <span
                  className={
                    this.props.required ? 'subtitle required' : 'subtitle'
                  }
                >
                  {this.props.label} {this.showError()}
                </span>
              ) : null}
              <div className="tag-input">
                <Tags
                  tags={this.props.tag || tagsList}
                  selectedTags={selectedTags}
                  addItem={this.addItem}
                  removeItem={this.removeItem}
                />
              </div>
              {this.props.showNote && (
                <div className="tag-note">タグは6つまで選択できます</div>
              )}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

TagsField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.any.isRequired,
  meta: PropTypes.object,
  dateFormat: PropTypes.string,
  onChanged: PropTypes.func,
  selected: PropTypes.object,
  tag: PropTypes.array,
  customForm: PropTypes.bool,
  maxSelectable: PropTypes.number,
  showNote: PropTypes.bool,
  required: PropTypes.bool
};

export default TagsField;